<template>
  <div class="">
    <div class="">
      <div class="">
        <div class="">
          <div
            v-if="isLoading"
            class="loader d-flex align-items-center justify-content-between"
          >
            <div class="d-flex align-items-center">
              <b-spinner small style="color: #4d1b7e"></b-spinner>
              <span class="ml-2">Loading Verified Senders </span>
            </div>
          </div>
          <!-- -->
          <b-skeleton-table
            v-if="isLoadingFirstTime"
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true }"
          ></b-skeleton-table>
          <b-table
            row-class="custom-row-class"
            v-else-if="items.length > 0"
            hover
            :responsive="true"
            head-class="text-center"
            class="bg-table-heading bg-table-heading"
            :fields="fields"
            :items="items"
            show-empty
          >
            <template #cell(letter)="data">
              <div
                class="text-uppercase d-flex align-items-center justify-content-center"
                style="height: 40px; display: block; width: 40px; color: white"
                :style="[{ background: randomColors[data.index] }]"
              >
                {{ checkFirstCharacter(data.item) }}
              </div>
            </template>
            <template #cell(nick_name)="data">
              {{ data.item.nick_name }}
            </template>
            <template #cell(verified)="data">
              <div
                v-if="data.item.verified"
                class="p-2 quizell-badge quizell-badge-active"
              >
                <span> Verified </span>
              </div>
              <div
                v-if="data.item.verified == 0"
                @click="reSender(data.item.id, 'verify')"
                class="p-2 quizell-badge quizell-badge-inactive w-100 d-flex gap align-items-center"
                style="min-width: 100%; max-width: 100%;"
              >
                <span>
                  <b-icon
                    v-b-tooltip.hover
                    title="Check Verification"
                    icon="arrow-clockwise"
                    :animation="data.item.id == reSenderId ? 'spin' : null"
                    font-scale="1.2"
                  ></b-icon>
                </span>
                Unverified
              </div>
            </template>
            <template #cell(created_at)="data">
              {{ formattedDate(data.item.created_at) }}
            </template>
            <template #cell(actions)="data">
              <b-spinner v-if="data.item.id == reSenderId" small></b-spinner>
              <b-dropdown
                v-else-if="data.item.from_email.toLowerCase() != defaultEmail"
                dropright
                class=""
                id="collection-detail"
              >
                <template slot="button-content">
                  <div class="p-2">
                    <i class="text-dark fa-solid fa-ellipsis-vertical"></i>
                  </div>
                </template>

                <b-dropdown-item
                  class="topbarLink d-flex align-items-center"
                  @click="
                    () => {
                      senderFormMode = 'update';
                      $refs.updateSenderEmail.open(data.item.id);
                    }
                  "
                >
                  Edit
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="data.item.verified == 0"
                  class="topbarLink d-flex align-items-center"
                  @click="reSender(data.item.id, 'resend')"
                >
                  Resend
                </b-dropdown-item>
                <!--  -->
                <b-dropdown-item
                  v-if="data.item.verified == 0"
                  class="topbarLink d-flex align-items-center"
                  @click="reSender(data.item.id, 'verify')"
                >
                  Check Verification
                </b-dropdown-item>
                <b-dropdown-item
                  class="topbarLink d-flex align-items-center"
                  @click="
                    () => {
                      deleteSenderId = data.item.id;
                      $bvModal.show('senderBulkDelete');
                    }
                  "
                >
                  Delete
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <template v-else>
            <div class="d-flex justify-content-center align-items-center">
              <EmptyTable>
                <template #title> No product found </template>
                <template #description>
                  Time to fill your shelves or refine your search. Remember,
                  your results will appear right here.
                </template>
              </EmptyTable>
            </div>
          </template>

          <QuizellPagination
            :total-rows="totalRows"
            :currentPage="currentPage"
            :per-page="perPage"
            v-if="items.length"
            @update="updatePagination"
            @UpdatePerPage="updatePerPage"
          ></QuizellPagination>
        </div>
      </div>
    </div>
    <Transition name="fade">
      <b-modal id="senderBulkDelete" title="Delete Sender">
        <!-- v-b-modal.senderBulkDelete -->
        <p class="my-4">
          Are you sure you want to delete the selected sender? This can’t be
          undone.
        </p>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              :class="isDeleteLoading ? 'disabled' : ''"
              variant="danger"
              @click="DeleteSender()"
            >
              <span v-if="!isDeleteLoading"> Confirm </span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>
    </Transition>

    <FooterContent>
      <div class="d-flex align-items-center justify-content-end">
        <Button @click="openCreateSenderModal()">+ Create Sender</Button>
      </div>
    </FooterContent>
    <SenderEmailForm
      ref="updateSenderEmail"
      @reload="getSenderList"
      senderFormMode="update"
    ></SenderEmailForm>
    <SenderEmailForm
      ref="createSenderEmail"
      @reload="getSenderList"
      :senderFormMode="senderFormMode"
    ></SenderEmailForm>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import SenderEmailForm from "./SenderEmailForm.vue";
import Button from "../../Layout/Button.vue";
import QuizellPagination from "../../Layout/QuizellPagination.vue";
import FooterContent from "../../Layout/FooterContent.vue";
import EmptyTable from "../../Layout/EmptyTable.vue";
import axios from "axios";
export default {
  components: {
    FooterContent,
    QuizellPagination,
    Button,
    SenderEmailForm,
    EmptyTable,
  },
  data() {
    return {
      senderFormMode: "create",
      defaultEmail: "no-reply@quizell.com",
      currentPage: 1,
      perPage: 10,
      totalRows: 20,
      randomColors: [],
      isDeleteLoading: false,
      deleteSenderId: "",
      reSenderId: "",
      isLoading: false,

      isLoadingFirstTime: true,
      fields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "data-Table cursor-pointer align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "letter",
          label: "",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "nick_name",
          label: "Nick Name",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "from_email",
          label: "From Email",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "reply_to_name",
          label: "Reply to name",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "reply_to_email",
          label: "Reply to email",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "verified",
          label: "Verified",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
          thStyle: { "min-width": "130px" },
        },

        {
          key: "created_at",
          label: "Date",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "actions",
          label: "",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
      ],
      items: [],
    };
  },
  methods: {
    ...mapMutations(["setUpgradePlansModal"]),
    updatePerPage(e) {
      this.currentPage = 1;
      this.perPage = e;
      this.getSenderList();
    },
    updatePagination(e) {
      this.currentPage = e;
      this.getSenderList();
    },

    async openCreateSenderModal() {
      if (!this.allowedSenderCreation) {
        let obj = {
          isOpen: true,
          title: "Upgrade to add more senders details",
          subText: `Your current plan does'nt allow to add more senders details. Please upgrade to higher plan to add more details`,
        };

        this.setUpgradePlansModal(obj);
      } else {
        this.senderFormMode = "create";

        this.$refs.createSenderEmail.open();
      }
    },

    async reSender(id, request) {
      
      try{
        this.reSenderId = id;
        const apiRequest = request=='resend'?'resend-email':'verify'
      const response = await axios.post(`/api/v1/email-senders/${id}/${apiRequest}`);
      if(response.status==200)
      {
        const toast = request == "resend" ? "Successfully Resent" : "Sender is verified"
        this.toast(toast);
      }
      else
      this.toast("Something went wrong!");
      }
      catch(e)
      {
        if(e.response.status==400)
      {
        this.toast(e.response.data.message.message);
      }
      else{
        this.toast("Something went wrong!");
      }
        throw e
      }
     finally{
      this.reSenderId = null;
     }

    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);

      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = String(date.getUTCFullYear());

      return `${day}-${month}-${year}`;
    },

    async DeleteSender() {
      this.isDeleteLoading = true;
      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "delete",
        resource: "verified_senders",
        method: "delete",
        id: this.deleteSenderId,
      });
      if (response && response.status == "success") {
        this.toast("Sender Deleted Successfully");
        this.deleteSenderId = "";
        this.getSenderList();
        this.$bvModal.hide("senderBulkDelete");
      } else this.toast("Something went wrong");
      this.isDeleteLoading = false;
    },
    async getSenderList() {
      this.isLoading = true;
      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "get",
        resource: "verified_senders",
        method: "list",
        params: { params: { page: this.currentPage, per_page: this.perPage } },
      });
      if (response && response.status == "success") {
        this.items = response.data.data;
        this.totalRows = response.data.total;
        this.generateRandomDarkColors();
      }

      this.isLoading = false;
      this.isLoadingFirstTime = false;
    },
    checkFirstCharacter(item) {
      if (item.nick_name) return item.nick_name.charAt(0).toUpperCase();
      else if (item.from_email) return item.from_email.charAt(0).toUpperCase();
      return "Q";
    },

    generateRandomDarkColors() {
      this.randomColors = [];
      var letters = "0123456789ABCDEF";
      var numItems = this.items.length;

      for (var i = 0; i < numItems; i++) {
        var color = "#";

        for (var j = 0; j < 6; j++) {
          var randomIndex = Math.floor(Math.random() * letters.length);
          color += letters[randomIndex];
        }

        this.randomColors.push(color);
      }
    },
    PaginationData() {
      this.getSenderList();
    },
    toast(text) {
      this.$toasted.show(text, {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
    },
  },
  computed: {
    ...mapGetters(["getUserAllAccountData"]),
    sendorLimitations() {
      return this.getUserAllAccountData.limitations.find(
        (item) => item.text === "Senders"
      );
    },
    allowedSenderCreation() {
      return (
        this.sendorLimitations &&
        this.sendorLimitations.userValue < this.sendorLimitations.planValue
      );
    },

    senderDetailsLength() {
      return this.items.length;
    },
  },

  async created() {
    await this.getSenderList();
  },
};
</script>

<style scoped>
.search-text:focus-visible {
  outline: none;
}
.search-text:active + .fa-search {
  color: #4d1b7e;
}
.tag {
  background: #bfbfc0;
  border-radius: 20px;
  border: 1px dashed;
  font-size: 14px;
}
.icon {
  color: #949495;
  transition: 0.5s ease all;
}
.icon:hover {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.search {
  border-radius: 6px;
  border: 0.8px solid #b3afb6;
}
.search:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.cursor-pointer {
  cursor: pointer;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.floatingDiv {
  position: fixed;
  top: auto;
  bottom: 20px;
  width: 90%;
}
s::after {
  content: "";
  width: 100%;
  display: block;
  border-radius: 4px;
  height: 1px;
  border: 1px solid rgb(206 206 210 / 25%);
}
.floatingDiv .InnerDiv {
  z-index: 999 !important;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0rem 0.125rem 0.25rem #1f21241a, 0rem 0.0625rem 0.375rem #1f21240d,
    0rem 0.25rem 1.125rem -0.125rem #1f212414,
    0rem 0.75rem 1.125rem -0.125rem #1f212426;
  padding: 10px;
}
.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}
.download-btn {
  border-radius: 4px;
  background: rgba(77, 27, 126, 0.2);
}
.download-title {
  color: #1f1f28;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.loader {
  min-height: 40px;
}
.bg-table-heading bg-table-heading >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.bg-table-heading bg-table-heading >>> thead tr {
  border-radius: 10px;
  cursor: pointer;
}
.bg-table-heading bg-table-heading >>> thead th {
  border: none;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.dropdown >>> .btn-danger {
  color: #4d1b7e;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.dropdown >>> .btn-danger:active {
  color: #3f1768;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.date >>> #Starting,
.date >>> #ending {
  /* color: #3f1768; */
  color: #4d1b7e;
}
.collapse-title {
  font-size: 18px;
  font-weight: 500;
}
.custom-row-class:hover {
  cursor: pointer; /* Change this to the desired cursor style */
}

.collapsed > .collapse-title > .when-opened,
:not(.collapsed) > .collapse-title > .when-closed {
  display: none;
}

.btn-filter {
  border-radius: 24px;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
</style>
